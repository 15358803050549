import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import HomepageTemplate from 'gatsby-theme-carbon/src/templates/Homepage';
export const _frontmatter = {
  "title": "Homepage"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const FeatureCard = makeShortcode("FeatureCard");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ResourceCard = makeShortcode("ResourceCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = HomepageTemplate;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <FeatureCard href="/getting-started/designers" label="Start" title="Designing" margin="true" className="feature-tile--designers" mdxType="FeatureCard">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "33.33333333333333%",
            "position": "relative",
            "bottom": "0px",
            "left": "0px",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Get started for designers",
          "title": "Get started for designers",
          "src": "/static/31c72ccdc02a2652a0b1c0242d4ce352/f65c8/getting-started-designers.png",
          "srcSet": ["/static/31c72ccdc02a2652a0b1c0242d4ce352/d6747/getting-started-designers.png 288w", "/static/31c72ccdc02a2652a0b1c0242d4ce352/09548/getting-started-designers.png 576w", "/static/31c72ccdc02a2652a0b1c0242d4ce352/f65c8/getting-started-designers.png 960w"],
          "sizes": "(max-width: 960px) 100vw, 960px"
        }}></img>{`
    `}</span>
    </FeatureCard>
    <FeatureCard href="/getting-started/developers/vanilla" label="Start" title="Developing" className="feature-tile--developers" mdxType="FeatureCard">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "33.33333333333333%",
            "position": "relative",
            "bottom": "0px",
            "left": "0px",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Get started for developers",
          "title": "Get started for developers",
          "src": "/static/737e429a1123dbb894bba45c3ce2d0e1/f65c8/getting-started-developers.png",
          "srcSet": ["/static/737e429a1123dbb894bba45c3ce2d0e1/d6747/getting-started-developers.png 288w", "/static/737e429a1123dbb894bba45c3ce2d0e1/09548/getting-started-developers.png 576w", "/static/737e429a1123dbb894bba45c3ce2d0e1/f65c8/getting-started-developers.png 960w"],
          "sizes": "(max-width: 960px) 100vw, 960px"
        }}></img>{`
    `}</span>
    </FeatureCard>
    <h3 {...{
      "id": "other-resources"
    }}>{`Other resources`}</h3>
    <p>{`The component libraries give developers a collection of reusable components for
building websites and user interfaces. See a
`}<a parentName="p" {...{
        "href": "/resources"
      }}>{`complete list of resources.`}</a></p>
    <Row className="tile--resource--no-margin tile--group" mdxType="Row">
      <Column offsetLg="4" colLg="4" colMd="4" noGutterSm mdxType="Column">
  <ResourceCard dark="true" title="Sketch libraries" href="/resources#theme-libraries" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "95%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "sketch icon",
              "title": "sketch icon",
              "src": "/static/aeb99a8308cb0fc718bd4c42a1a0aba3/7d71f/sketch-icon.png",
              "srcSet": ["/static/aeb99a8308cb0fc718bd4c42a1a0aba3/7d71f/sketch-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column colLg="4" colMd="4" noGutterSm mdxType="Column">
  <ResourceCard dark="true" title="Carbon Components" href="https://github.com/carbon-design-system/carbon/tree/master/packages/components" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "98%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "github icon inverted",
              "title": "github icon inverted",
              "src": "/static/3308cfe3f786b2960dd7debe58d62c48/7d71f/github-icon-inverted.png",
              "srcSet": ["/static/3308cfe3f786b2960dd7debe58d62c48/7d71f/github-icon-inverted.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column offsetLg="4" colLg="4" colMd="4" noGutterSm mdxType="Column">
  <ResourceCard dark="true" title="Carbon Components React" href="https://github.com/carbon-design-system/carbon/tree/master/packages/react" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "98%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "github icon inverted",
              "title": "github icon inverted",
              "src": "/static/3308cfe3f786b2960dd7debe58d62c48/7d71f/github-icon-inverted.png",
              "srcSet": ["/static/3308cfe3f786b2960dd7debe58d62c48/7d71f/github-icon-inverted.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column colLg="4" colMd="4" noGutterSm mdxType="Column">
  <ResourceCard dark="true" title="Carbon Components Angular" href="https://github.com/ibm/carbon-components-angular" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "98%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "github icon inverted",
              "title": "github icon inverted",
              "src": "/static/3308cfe3f786b2960dd7debe58d62c48/7d71f/github-icon-inverted.png",
              "srcSet": ["/static/3308cfe3f786b2960dd7debe58d62c48/7d71f/github-icon-inverted.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column offsetLg="4" colLg="4" colMd="4" noGutterSm mdxType="Column">
  <ResourceCard dark="true" title="Carbon Components Vue" href="https://github.com/carbon-design-system/carbon-components-vue" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "98%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "github icon inverted",
              "title": "github icon inverted",
              "src": "/static/3308cfe3f786b2960dd7debe58d62c48/7d71f/github-icon-inverted.png",
              "srcSet": ["/static/3308cfe3f786b2960dd7debe58d62c48/7d71f/github-icon-inverted.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
    </Row>
    <h3 {...{
      "id": "latest-articles"
    }}>{`Latest articles`}</h3>
    <Row mdxType="Row">
      <Column offsetLg="4" colLg="4" colMd="4" noGutterMdLeft mdxType="Column">
        <ResourceCard type="article" dark="true" title="Carbon June(ish) preview" author="Alison Joseph" date="June 13, 2019" href="https://medium.com/carbondesign/carbon-june-ish-preview-9c5843258933" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "june option 3",
              "title": "june option 3",
              "src": "/static/54a53bcd8760b98215387b6438fdf848/fb070/june_option_3.png",
              "srcSet": ["/static/54a53bcd8760b98215387b6438fdf848/d6747/june_option_3.png 288w", "/static/54a53bcd8760b98215387b6438fdf848/09548/june_option_3.png 576w", "/static/54a53bcd8760b98215387b6438fdf848/fb070/june_option_3.png 1152w", "/static/54a53bcd8760b98215387b6438fdf848/dee76/june_option_3.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px"
            }}></img>{`
    `}</span>
        </ResourceCard>
      </Column>
      <Column colLg="4" colMd="4" noGutterMdLeft mdxType="Column">
        <ResourceCard dark="true" type="article" title="Carbon v10.3.0 (May 2019)" author="Josh Black" date="June 6, 2019" href="https://medium.com/carbondesign/carbon-v10-3-0-may-2019-41b1f970f0ce" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "10 3 image",
              "title": "10 3 image",
              "src": "/static/bf06d74c14c38c42cfc9243126462ec2/fb070/10_3-image.png",
              "srcSet": ["/static/bf06d74c14c38c42cfc9243126462ec2/d6747/10_3-image.png 288w", "/static/bf06d74c14c38c42cfc9243126462ec2/09548/10_3-image.png 576w", "/static/bf06d74c14c38c42cfc9243126462ec2/fb070/10_3-image.png 1152w", "/static/bf06d74c14c38c42cfc9243126462ec2/dee76/10_3-image.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px"
            }}></img>{`
    `}</span>
        </ResourceCard>
      </Column>
      <Column colLg="4" colMd="4" noGutterMdLeft mdxType="Column">
        <ResourceCard dark="true" type="article" title="Essentials: Learn to build with Carbon" author="Matt Rosno" date="May 15, 2019" href="https://medium.com/carbondesign/essentials-learn-to-build-with-carbon-1d11ce05125f" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "code palette",
              "title": "code palette",
              "src": "/static/1a67f5c2bd6548d9e8bb0368814a7d1b/fb070/code_palette.png",
              "srcSet": ["/static/1a67f5c2bd6548d9e8bb0368814a7d1b/d6747/code_palette.png 288w", "/static/1a67f5c2bd6548d9e8bb0368814a7d1b/09548/code_palette.png 576w", "/static/1a67f5c2bd6548d9e8bb0368814a7d1b/fb070/code_palette.png 1152w", "/static/1a67f5c2bd6548d9e8bb0368814a7d1b/dee76/code_palette.png 1600w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px"
            }}></img>{`
    `}</span>
        </ResourceCard>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      